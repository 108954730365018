var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ab36792363a2cbc402cd6d0c62430d8a6c73c350"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole as CaptureConsoleIntegration,
} from '@sentry/integrations';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  release: process.env.VERCEL_GITHUB_COMMIT_SHA,
  environment: process.env.NEXT_PUBLIC_ONBOARDING_ENV,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  normalizeDepth: 5,
  beforeSend: (event) => {
    if (event?.contexts?.Error?.config?.headers['X-Authorization-App']) {
      // eslint-disable-next-line
      event.contexts.Error.config.headers['X-Authorization-App'] = 'Removed before sending to sentry';
    }
    if (event?.contexts?.Error?.config?.headers.Authorization) {
      // eslint-disable-next-line
      event.contexts.Error.config.headers.Authorization = 'Removed before sending to sentry';
    }
    return event;
  },
  integrations: (list) => [
    ...list,
    new ExtraErrorDataIntegration({
      depth: 5,
    }),
    new CaptureConsoleIntegration({
      levels: ['error', 'debug', 'warn'],
    }),
  ],
});
